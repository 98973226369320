<template>
  <div class="news view-container">
    <MainCard
      :data="newsData"
      class="main-card"/>
    <!-- 新闻列表 -->
    <ul class="news-list">
      <li class="news-item" @click="handleJumpToDetail">
        <p class="content">
          {{ $t('2021.7.18-2021.7.25 四川凉山州彝族自治州冕宁县走访报告') }}
        </p>
        <div class="right-box">
          <span class="date">
            2021/08/16
          </span>
          <AliIcon
            name="icon-dongtaijiantou1"
            class="ali-icon"/>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import image from '@/assets/image/news/main.png'
import h5Image from '@/assets/image/news/main-h5.png'
export default {
  data () {
    return {
      newsData: {
        title: '公益动态',
        subtitle: 'News',
        image,
        h5Image
      }
    }
  },
  methods: {
    handleJumpToDetail () {
      this.$router.push({
        name: 'NewsDetail'
      })
    }
  }
}
</script>

<style scoped lang="less">
.news {
  position: relative;
  padding-top: 50px;
  .main-card {
    margin-bottom: 50px;
  }
  .news-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0 22px 22px;
    border-bottom: 1px solid #d8d8d8;
    cursor: pointer;
    &:hover {
      .content {
        color: #0056c7;
      }
      .date {
        color: #0056c7;
      }
      .ali-icon {
        color: #0056c7;
      }
    }
    &::before {
      position: absolute;
      top: 50%;
      left: -1px;
      transform: translateY(-50%) scale(2/3);
      content: '';
      width: 8px;
      height: 8px;
      border: 2px solid #0056C7;
      border-radius: 8px;
    }
    .content {
      font-size: 16px;
      color: #1D2130;
      line-height: 16px;
    }
    .right-box {
      display: flex;
      align-items: center;
      color: #6D6E77;
      .date {
        font-size: 16px;
        line-height: 16px;
      }
      .ali-icon {
        margin-left: 12px;
        font-size: 20px;
      }
    }
  }
}
@media (max-width: 750px) {
  .news {
    padding-top: 15px;
    .main-card {
      margin-bottom: 0;
    }
    .news-item {
      padding: 19px 4px 19px 15px;
      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        content: '';
        width: 7px;
        height: 7px;
        border: 1px solid #0056C7;
        border-radius: 5px;
        box-sizing: border-box;
      }
      .content {
        flex: 1;
        font-size: 14px;
        line-height: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .right-box {
        .date {
          display: none;
        }
        .ali-icon {
          margin-left: 10px;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
